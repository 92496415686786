import React from 'react';

const ImpactTheCost = () => {
  return (
    <div>
          <div className="takeaways">
            <h2>Key Takeaways</h2>
            <ul>
              <li>A longer-term mortgage means keeping monthly payments to a minimum but means paying more interest overall</li>
              <li>
              If you want to minimise the amount you pay in interest, a shorter-term mortgage may be better, but this means higher monthly payments
              </li>
            </ul>
          </div>
          <div className="transcript">
            <h2>Transcript</h2>
            <p>
              How does the mortgage term impact the cost?<br/><br/>
              A mortgage is more than likely the largest and longest financial commitment that you'll take in your lifetime, so it's important to understand how different mortgage terms impact the overall cost. <br/><br/>
              You'd be surprised at how much it can vary and the impact it can have if you take out a longer-term mortgage.<br/><br/>
              The major benefit of this is you will keep your mortgage payments to a minimum, but also more important for initial homeowners joining the housing ladder the mortgage payments become more affordable and allow you to settle into homeownership and adapt to household expenses.<br/><br/>
              On the other hand, a longer-term mortgage means that you'll pay more interest over a longer period of time, which means the cost of your mortgage can increase significantly.<br/><br/>
              If you're not concerned about lowering your monthly payments and you're more concerned about the total cost of your mortgage, a shorter mortgage will minimise the amount of interest you'll be charged overall and you'll be able to pay it off a lot quicker. However, the monthly payments will increase.<br/><br/>
              Therefore, the banks will access if the mortgage is still affordable.<br/><br/>
            </p>
          </div>
    </div>
  );
};

export default ImpactTheCost;
